// 
// tables.scss
//


//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}


// SolidCert tables

$table-border: lighten($sc-light-slate, 10%);

.tr {
    border-bottom: 1px solid $sc-light-slate;
    position: relative;
}
.header-row {
    border-bottom: none;
    margin-bottom: 20px;
}
.th {
    font-weight: 600;
    color: $sc-onyx;
}
.td {
    color: $sc-dark-slate;
    &.valid {
        background-image: url(../images/icons/valid.svg);
    }
    &.invalid {
        background-image: url(../images/icons/invalid.svg);
    }
    &.pending {
        background-image: url(../images/icons/pending.svg);
    }
    &.action {
        a {
            color: $sc-mint;
            font-size: 1.6rem;
            &.disabled {
                pointer-events: none;
                color: $sc-light-slate;
            }
        }
    }
    a {
        color: $sc-dark-slate;
        &:hover {
            color: $sc-onyx;
        }
    }
}

// Functionality for responsive tables
.responsive-table-deluxe {

    .tr {
        display: flex;
        align-items: center;
    }
    .th {
        font-size: 1.1rem;
    }
    .td {
        font-size: 0.9375rem;
    }
    .th, .td {
        padding: 12px 2px;
        vertical-align: middle !important;
    }
    .status-icon  {
        width: 20px;
        height: 38px;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 0;
        margin-right: 10px;
    }
    .primary-data, .secondary-data {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .secondary-data { 
        display: none;
    }
    .expand-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 10px;
        right: -10px;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
        border: none;
        i {
            color: $sc-light-slate;
            font-size: 0.875rem;
        }
    }
    .action {
        display: none;
    }

    @media screen and (min-width: 768px) {
        .action {
            display: block;
        }
        .view-cert-button {
            display: none;
        }
    } 

    // OK to use max-width here for expanded rows which show only on mobile? Who knows!
    @media screen and (max-width: 767px) {
        .tr.expanded {
            display: block;
            .td {
                padding: 12px 0 0;
            }
            .primary-data {
                flex-wrap: wrap;
            }
            .secondary-data {
                display: block;
                margin-bottom: 30px;
                margin-left: 5px;
                height: auto;
            }
            .data-label {
                color: $sc-medium-slate;
                &::before {
                    content: attr(data-th);
                    display: inline-block;
                    font-weight: 600;
                    width: 110px;
                    font-size: 0.875rem;
                    color: $sc-dark-slate;
                }
            }
            .expand-icon {
                transition: all 0.2s;
                transform: rotate(180deg);
            }
        }
    } 
}


// Specific layout styles for profile Certifications table
.profile-certifications-table {

    [data-th="Certifications"] {
        width: 75%;
    }
    [data-th="Status"] { 
        width: 20%;
        &.th {
            text-align: center;
        }
        &.td {
            display: none;
        }
    }
    .status-icon {
        width: 20%;
    }
    .view-cert-button {
        margin-top: 20px;
        width: calc(100% - 10px);
    }

    @media screen and (min-width: 768px) {
        .primary-data {
            width: 60%;
        }
        .secondary-data {
            width: 40%; 
            display: flex;
            justify-content: space-between;
        }
        .expand-icon { 
            display: none; 
        }
        
        [data-th="Status"] {
            &.th { 
                text-align: left;
            }
            &.td {
                display: block;
            }
        }
        [data-th="Certifications"] { 
            width: 60%; 
            &.th {
                width: 60%;
            }
        }
        [data-th="Status"] { 
            display: block;
            width: calc( 40% - 40px ); 
            &.th {
                width: 40%;
            }
        }
        .status-icon {
            width: 20px;
            background-position: left center;
        }

        [data-th="Verified by"] { 
            width: 55%;
        }
        [data-th="Expiration"]  { 
            width: 30%; 
            min-width: 100px;
        }
        .action { 
            width: 15%; 
            text-align: right;
        }
    } 

    @media screen and (max-width: 767px) {
        .tr.expanded {
            [data-th="Certifications"] {
                font-weight: 600;
                font-size: 1.1rem;
                color: $sc-dark-slate;
            }
            [data-th="Status"] {
                display: block; 
                width: 100%;
                margin-top: 10px;
                margin-left: 5px;
            }
            .status-icon {
                background-position: center 12px;
            }
        }   
    } 
       
}
